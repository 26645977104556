import React from "react"

import './MainTitle.css'

export default function MainTitle() {

    return (
        <div className="header">
            <div className="header-title">

                <p className="small-title">Hi, I am</p>
                <h1>Elie Baier.</h1>
                <h2>High School Student.</h2>
                <p className="small-text">
                    {/* I'm a math and physics student. <br /> */}
                    I love learning news things and talking with computers. <br />
                    I build and sometimes design UI & Webpages.
                </p>

                <button className="btn-primary"><a href="#contact">Contact me!</a></button>

            </div>
        </div>
    )

}