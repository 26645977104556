import React from "react";

import './Contact.css'

export default function Contact() {

    return (

        <div className="contact" id="contact">

            <h4 className="balise"><span className="unselectable">&lt;</span>contact<span className="unselectable">&gt;</span></h4>

            <div className="contact-container">

                <h1 className="contact-title">Get In Touch</h1>
                <h5><a href="mailto:elie.baier@icloud.com">elie.baier@icloud.com</a></h5>

                <p>You can contact me either by mail or on twitter/Instagram. <br />Come say hi!</p>

                <button className="btn-primary"><a href="mailto:elie.baier@icloud.com">Say Hello</a></button>

            </div>

            {/* <h4 className="balise"><span className="unselectable">&lt;/</span>contact<span className="unselectable">&gt;</span></h4> */}

        </div>

    )

}