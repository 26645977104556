import React from "react"
import './Footer.css'

export default function Footer() {

    return (

        <div className="footer">

            <h5>Designed &amp; Built by Elie "Fayorg" Baier</h5>

        </div>

    )

}