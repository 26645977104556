import React from "react";

import "./FixedSocialMedia.css"

import github from './../media/icons/github.svg'
import twitter from './../media/icons/twitter.svg'
import instagram from './../media/icons/instagram.svg'

export default function FixedSocialMedia() {

    return (
        <div className="main">
            <div className="social">

                <a href="https://github.com/Fayorg" target={"_blank"}><img src={github} alt="Social Media" width={39.92} /></a>
                <a href="https://twitter.com/zattair" target={"_blank"}><img src={twitter} alt="Social Media" width={39.92} /></a>
                <a href="https://www.instagram.com/elieatetim/" target={"_blank"}><img src={instagram} alt="Social Media" width={39.92} /></a>

            </div>

            <div className="email">

                <a href="mailto:elie.baier@icloud.com"><p>elie.baier@icloud.com</p></a>

            </div>

        </div>
    )

}