import React from "react";

import './InterComponents.css'
import triangle from './../media/icons/triangle.svg'

export default function InterComponents({ objectOrder }) {

    return (
        <div className="middlepart">

            {objectOrder.map(obj => {

                if(obj == 'triangle') {
                    return (
                        <div className="triangle">
                            <img src={triangle} width={43} />
                        </div>
                    )
                } else {
                    return (
                        <div className={obj}></div>
                    )
                }

            })}

        </div>
    )

}