import React from "react";

import './About.css'
import elie from '../media/images/ElieBaier.JPG'

export default function About() {

    return (
        <div className="about">
            <div className="about-text">

                <h4 className="balise"><span className="unselectable">&lt;</span>about<span className="unselectable">&gt;</span></h4>

                <p>
                    Hello! My name is Elie, I live in Switzerland and 
                    I study maths &amp; physics at the cantonal high school in Nyon (outside Geneva). In my free time
                    I like learning things related to computing and 
                    IT. <br /> <br />

                    I started programming in Java at age 10, when I got my first 
                    laptop and never stopped since! Early on I was
                    interested in web development and taught 
                    myself some basic HTML, CSS and JavaScript. <br /> <br />

                    Today, I create simple websites for small and
                    medium sized companies and I run my own servers (or HomeLab) 
                    in my basement. When I have time I like working on my 
                    own projects and learning new technologies. Check out my projects.
                </p>

                {/* <h4 className="balise"><span className="unselectable">&lt;/</span>about<span className="unselectable">&gt;</span></h4> */}

            </div>
            <div className="about-image">

                <img src={"https://cdn.aketechnology.com/aketechnology%2Fuser%2FElieBaier-2.jpeg"} />
                <div></div>

            </div>
        </div>
    )

}