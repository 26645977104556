import logo from './logo.svg';
import './App.css';

import FixedSocialMedia from './Component/FixedSocialMedia';
import MainTitle from './Component/MainTitle';
import InterComponents from './Component/InterComponents';
import About from './Component/About';
import Experience from './Component/Experience';
import Contact from './Component/Contact';
import Footer from './Component/Footer';
import Projects from './Component/Projects';

function App() {
  return (
    <div className="App">
      
      <div className='App-data'>
      <FixedSocialMedia />
      <MainTitle />
      <InterComponents objectOrder={['circle', 'square', 'triangle']}/>
      <About />
      <InterComponents objectOrder={['square', 'triangle', 'circle']}/>
      <Projects />
      <InterComponents objectOrder={['triangle', 'circle', 'square']}/>
      <Experience />
      <InterComponents objectOrder={['square', 'triangle', 'circle']}/>
      <Contact />
      <InterComponents objectOrder={['circle', 'square', 'triangle']}/>
      <Footer />
      </div>

      <h1 className='App-screen'>You need a bigger device to see this website!</h1>

    </div>
  );
}

export default App;
