import React from 'react';
import './Projects.css';

import folder from '../media/icons/folder.svg';
import faybank from '../media/icons/faybank.svg';
import bitgrades from '../media/icons/bitgrades.svg';
import walfy from '../media/icons/walfy.svg';
import ottoman from '../media/icons/ottoman.svg';

export default function Projects() {
	const data = [
		{ title: 'BitGrades', description: 'IOS & Android app to easily track school grades. Supported by high school management to make the app available to the entire students population.', link: 'https://apps.apple.com/app/bitgrades/id6444346489', logo: bitgrades },
		{ title: 'Walfy', description: 'Small and lightweight Java 2D game/application engine. Support for inputs, menus, sounds & lights.', comingSoon: true, logo: walfy },
		{ title: 'AkeTechnology', description: 'Website for a small IT compangy made with NextJS', link: '#', logo: faybank },
		{ title: 'Ottoman', description: 'Discord bot written in Golang to solve math problems', comingSoon: true, logo: ottoman },
	];

	return (
		<div className="projects">
			<h4 className="balise">
				<span className="unselectable">&lt;</span>projects<span className="unselectable">&gt;</span>
			</h4>

			<div className="projects-container">
				{data.map((data) => {
					return (
						<div className="projects-card">
							<img src={data.logo ? data.logo : folder} />
							<h6>{data.title}</h6>
							<p>{data.description}</p>
							{data.link && (
								<a href={data.link} target={'_blank'}>
									See more
								</a>
							)}
							{data.comingSoon && <a>Available soon</a>}
						</div>
					);
				})}
			</div>

			{/* <h4 className="balise"><span className="unselectable">&lt;/</span>projects<span className="unselectable">&gt;</span></h4> */}
		</div>
	);
}
