import React from "react";

import './Experience.css'

const data = [
        {title: 'Secondary School', date: 'August 2018 - July 2021', text: "I completed secondary school in Lausanne following the maths and physics university track program.", key: 0},
        {title: 'IT Apprenticeship', date: 'September 2021 - August 2022', text: "For a year I worked for DigitalCanion, a compagny active in IT networking. I gathered experience in networking installations, interacting with customers, creating websites and simple programming.", key: 1},
        {title: 'High School', date: 'August 2022 - Present', text: "Continuing the maths and physics university track program. Expected graduation in June 2024.", key: 2},
        {title: 'Next ?', date: 'Next few years', text: "My ambition is to go to the Swiss Federal Institute of Technology in Lausanne (ranked 21st according to QS World University Rankings 2022).\nUltimately, I would like to pursue a career in programming or cybersecurity, but in the mean time I would like to gather practical experience during my school holidays.", key: 3}]

export default function Experience() {

    const [selected, setSelected] = React.useState(0);

    const press = (id) => {
        setSelected(id);
    }
      

    return (
        <div className="experience">
            
            <h4 className="balise"><span className="unselectable">&lt;</span>education<span className="unselectable">&gt;</span></h4>

            <div className="exp-container">

                <div className="exp-selector">

                    {data.map((util, i) => {

                        return (
                            <div onClick={() => { press(util.key) }} className={(util.key === selected) ? "exp-selected" : ''}>

                                <h4>{util.title}</h4>

                            </div>
                        )

                    })}

                </div>

                <div className="exp-text">

                    <h2>{data[selected].title}</h2>
                    <h5>{data[selected].date}</h5>
                    <p style={{ whiteSpace: 'pre-wrap' }}>{data[selected].text}</p>

                </div>

            </div>

            {/* <h4 className="balise"><span className="unselectable">&lt;/</span>education<span className="unselectable">&gt;</span></h4> */}

        </div>
    )

}